<template>
  <div :class="gradientClass(props.gradientStyle)" />
</template>

<script lang="ts" setup>
const props = defineProps<{
  gradientStyle: string
}>()

function gradientClass(gradientStyle: string) {
  switch (gradientStyle) {
    case 'blue':
      return 'bg-gradient-blue'
    case 'beige':
      return 'bg-gradient-beige'
    case 'gray':
      return 'bg-gradient-gray'
    default:
      return ''
  }
}
</script>
